
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'EmptyInfo',
  props: {
    /**
     * @param delay
     * delay for showing data
     */
    delay: { required: false, default: 0, type: Number }
  },
  setup(props: any) {
    const show = ref(false);
    onMounted(() =>
      setTimeout(() => {
        show.value = true;
      }, props.delay)
    );

    return {
      show
    };
  }
});
