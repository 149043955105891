<template>
  <div :class="['empty-info', show ? 'show' : '']">
    <font-awesome-icon
      icon="box-open"
      height="16"
      class="box-open-icon-empty-info"
    />
    {{ $t('base.noData') }}
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'EmptyInfo',
  props: {
    /**
     * @param delay
     * delay for showing data
     */
    delay: { required: false, default: 0, type: Number }
  },
  setup(props: any) {
    const show = ref(false);
    onMounted(() =>
      setTimeout(() => {
        show.value = true;
      }, props.delay)
    );

    return {
      show
    };
  }
});
</script>

<style scoped lang="scss">
.empty-info {
  max-width: max-content;
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.225rem;
  font-weight: 400;
  color: var(--main-color);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
}
.box-open-icon-empty-info {
  margin-right: var(--app-base-space);
}
</style>
